<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container" @click.stop v-if="getModalInfo">
        <div class="close-div" v-if="!openCropper">
          <img @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>

        <div class="gallery-container" v-if="!openCropper">
          <div class="gallery-modal-header" style="font-weight: 600; font-size: 18px; color: #2d3640;">Mevcut Portfolyondan Seçim Yap veya Yeni Portfolyo Ekle</div>
          <div class="separator" style="border-top:1px solid #e1e1e1;!important;"></div>
          <div class="section-container">
            <div :class="['section',{'active-section': section === 1}]" @click="section = 1">
              <img src="https://gcdn.bionluk.com/site/icon/ic_filter_frames.svg" v-if="section === 1"/>
              <img src="https://gcdn.bionluk.com/site/icon/ic_filter_frames_gray.svg" v-else/>
              <p :class="['section-text',{'active-text': section === 1}]">Mevcut Portfolyomdan Seç</p>
            </div>
            <div :class="['section',{'active-section': section === 2}]" @click="section = 2">
              <img src="https://gcdn.bionluk.com/site/icon/aws/ic_add_circle_outline.svg" v-if="section === 2"/>
              <img src="https://gcdn.bionluk.com/site/icon/aws/ic_add_circle_outline_gray.svg" v-else/>
              <p :class="['section-text',{'active-text': section === 2}]">Yeni Yükle</p>
            </div>
          </div>
          <div class="separator" style="border-top:1px solid #e1e1e1;!important;margin: 0; box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px 0px;"></div>
          <div class="all-images-container" v-if="section === 1">
            <div class="all-images-inner">
              <div :class="['gallery-photo-box', { 'selected-box': selectedPortfolioImage.uuid === port.uuid }]" v-for="port in nonSelectedPortfolios" @click="pickPhoto(port)">
                <img class="gallery-photo" :src="port.cover.image_url_small"/>
                <!--<img @click="deletePortfolio(port)" class="iconshadow"-->
                <!--:src="$root.CDN_SITE + 'icon/ic_delete.svg'">-->
                <img src="https://gcdn.bionluk.com/site/icon/ic_play_circle_fill.svg" class="play-icon" v-if="port.file_type !== 'image'"/>
                <div class="zoom-icon-container" @click="showPortfolioDetailModal(port)">
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_zoom_white.svg" class="zoom-icon"/>

                </div>
              </div>
            </div>
          </div>

          <div class="upload-image-container" v-if="section === 2">
            <div class="portfolio-add-left-side">
              <div>
                <label class="portfolio-title-header">Portfolyo Başlığı *</label>
                <input maxlength="50" v-model="title" placeholder="İş örneklerim..." style="width: 230px;"/>
              </div>
              <div class="hiddendiv20"></div>

              <div v-show="openCover">
                <label class="portfolio-cover-header">Portfolyo Kapak Resmi</label>
                <div class="cover-container-passive" v-if="!uploadedCover.image_url && !dragAndDropArea">
                  <label for="filesPortfolioCover" class="cover-label-container">
                    <progress v-show="responseStatusToUploadCover === 'started'" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
                    <div style="padding-top: 36px;">
                      <img v-if="!responseStatusToUploadCover" :src="imageURL">
                      <div v-if="!responseStatusToUploadCover" class="cover-header">
                        Kapak Görseli
                      </div>
                    </div>
                    <input id="filesPortfolioCover" style="visibility:hidden;" type="file" @change="onFileChangeToUploadCover" v-if="responseStatusToUploadCover !== 'started'">
                  </label>
                </div>
                <div id="gallery-drag-drop-cover"
                     v-if="!uploadedCover.image_url && dragAndDropArea"
                     draggable="true"
                     @drop="dropHandler($event, 'cover')"
                     @dragover="dragOverHandler($event)">
                  {{dragDropTextCover}}
                </div>
                <div class="cover-container-active" v-if="uploadedCover.image_url">
                  <img @click="removeCoverUpload()"
                       class="cover-remove-button"
                       :src="$root.CDN_SITE + 'icon/ic_delete.svg'">
                  <img :style="{width: `${uploadedCover.width}px`, height: `${uploadedCover.height}px`}" :src="uploadedCover.image_url"
                       @load="coverImageOnload($event, uploadedCover)">
                  <loader v-if="uploadedCover.loader"></loader>
                </div>

                <p style="font-size: 12px; margin-top: 10px; width: 300px;"> - Kapak görseli yüklenmediği takdirde otomatik olarak oluşturulacaktır.<br><br> - Portfolyo kapak görseli için önerilen boyut 16:9<br> - Sadece png ve jpg formatında görseller yüklenebilir.<br> - Tavsiye edilen maksimum
                  genişlik 1400px<br> - Maksimum dosya boyutu 2mb</p>

                <button @click="savePortfolio()" :disabled="disableToSaveForAddPortfolio" class="save-button">Portfolyoma Ekle</button>
                <p v-if="disableToSaveForAddPortfolio" style="font-size: 12px; padding-top: 10px">Lütfen İş örneğine bir başlık yaz</p>
              </div>
            </div>

            <div class="portfolio-add-right-side">
              <label class="portfolio-media-header">İçerik Ekle</label>

              <div class="media-container-passive" v-if="!uploadedMedia.image_url && !dragAndDropArea">
                <label for="filesPortfolio3" class="media-label-container">
                  <progress v-show="uploadResponseStatus === 'started'" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
                  <div style="padding-top: 90px;">
                    <img v-if="!uploadResponseStatus" :src="imageURL">
                    <div class="image-header" v-if="!uploadResponseStatus">
                      Yeni İçerik Ekle
                    </div>
                  </div>
                  <input id="filesPortfolio3" style="visibility:hidden;" type="file" @change="onFileChange" v-if="uploadResponseStatus !== 'started'">
                </label>
              </div>
              <div id="gallery-drag-drop-media"
                   v-if="!uploadedMedia.image_url && dragAndDropArea"
                   draggable="true"
                   @drop="dropHandler($event, 'media')"
                   @dragover="dragOverHandler($event)">
                {{dragDropTextMedia}}
              </div>

              <div class="video-container" v-if="uploadedMedia.fileType === 'video'">
                <img src="https://gcdn.bionluk.com/site/icon/icon_check_green.svg">
                <div class="video-separator"></div>
                <p class="warning-text"> Yükleme Başarılı. Ekleme işlemine devam edebilirsin. Yüklediğin video işlendikten sonra otomatik olarak iş ilanı sayfanda gözükecek. </p>
              </div>

              <div class="media-container-active" v-if="uploadedMedia.image_url">
                <img @click="removeMediaUpload()" class="media-remove-button" :src="$root.CDN_SITE + 'icon/ic_delete.svg'"/>
                <img class="media-image" :src="uploadedMedia.image_url" @load="imageOnload($event, uploadedMedia)" v-if="uploadedMedia.fileType === 'image' || uploadedMedia.fileType === 'video'"/>
                <div class="audio-container" v-if="uploadedMedia.fileType === 'audio'">
                  <img @click="removeMediaUpload()" class="media-remove-button" :src="$root.CDN_SITE + 'icon/ic_delete.svg'"/>
                  <img src="https://gcdn.bionluk.com/site/icon/ic_play_circle_fill.svg" class="media-play-icon" @click="activateIframe()" v-if="!activatedIframe"/>
                  <img class="media-image" :src="uploadedMedia.image_url" @load="imageOnload($event, uploadedMedia)" v-if="!activatedIframe"/>
                  <audio
                    v-if="activatedIframe"
                    controls
                    :src="uploadedMedia.videoUrl">
                  </audio>
                </div>
                <loader v-if="uploadedMedia.loader"></loader>
              </div>
              <div class="hiddendiv10"></div>
              <p style="font-size: 12px; width: 370px"> - Sadece resim, video veya ses dosyası ekleyebilirsin.</p>
              <p style="font-size: 12px; width: 370px; margin-top: 5px;"> - Geçerli dosya formatları: <strong>.jpg, .png, .mp4 ve .mp3</strong></p>
              <p style="font-size: 12px; width: 370px; margin-top: 5px;"> - Resim dosyaları için maksimum boyut 2 mb; video ve ses dosyaları için maksimum boyut 300 mb ve 90 saniyedir.</p>

            </div>
          </div>


          <div class="separator"></div>
          <div class="button-container" v-if="section === 1  ">
            <button @click="hide()" class="back-button">İptal</button>
            <button @click="sendImageToGig()" :disabled="isEmpty(selectedPortfolioImage)" class="next-button">Seç ve Ekle</button>
          </div>
        </div>
        <cropper v-if="openCropper"
                 :file="file"
                 :fileType="fileType"
                 :canvasWidth="canvasWidth"
                 :canvasHeight="canvasHeight"
                 :divider="divider"
                 :viewMode="viewMode"></cropper>
      </div>
    </div>
  </transition>

</template>

<script>
  import cropper from '../../components/cropper/cropper.vue'
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: "src-pages-modals-gallery-v1",
    components: {
      cropper
    },
    data() {
      return {
        dragAndDropArea: false,
        dragDropText: 'Dosyaları bu alana sürükle.',
        section: 1,
        selectedCategory: null,
        selectedSubCategory: null,
        nonSelectedPortfolios: [],
        selectedPortfolios: [],
        selectedPortfolioImage: {},
        hoveredPortfolio: {},
        uuid: null,
        activeProgress: false,

        title: 'İş örneğim',
        imageURL: `${this.$root.CDN_SITE}icon/icon_add_pink.svg`,
        timeStamp: null,
        uploadedMedia: [],
        responseStatusToUploadCover: null,
        uploadResponseStatus: null,
        uploadedCover: {},
        mediaFile: null,
        disableDelete: false,
        activatedIframe: false,


//        for  cropper
        file: null,
        openCover: false,
        openCropper: false,
        canvasWidth: 1400,
        canvasHeight: 788,
        divider: 2.5,
        viewMode: 1,
        fileType: "onFileChangeToUploadCover",


        // dragdrop
        dragDropTextCover: '',
        dragDropTextMedia: ''
      }
    },
    methods: {
      isEmpty,
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      pickPhoto(portfolio) {
        this.selectedPortfolioImage = portfolio;
      },

      initData() {
        this.uploadedCover = {};
        this.timeStamp = null;
        this.mediaFile = null;
        this.uploadResponseStatus = null;
        this.responseStatusToUploadCover = null;
        this.section = 1;
        this.openCover = false;
        this.title = '';
      },
      savePortfolio() {
        let portfolio = {
          category_id: this.selectedCategory,
          category_sub_id: this.selectedSubCategory,
          name: this.title,
          description: this.description,
          upload_id: this.uploadedMedia.upload_id,
          cover_id: this.uploadedCover.upload_id,
          type: "upload"
        };

        this.activeProgress = true;
        this.api.seller.createPortfolioV2(portfolio, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.selectedPortfolios.push(result.data);
              this.EventBus.$emit('galleryPortfolio', {
                nonSelectedPortfolios: this.nonSelectedPortfolios,
                selectedPortfolios: this.selectedPortfolios
              });
              this.hide();
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.activeProgress = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      deletePortfolio(port) {
        if (confirm('Bu projeyi silmek üzeresin. Eğer bu portfolyo parçası bir iş ilanına bağlıysa oradan da silinecek. Bu yapmak istediğine emin misin?')) {
          this.api.seller.deletePortfolio(port.uuid, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.nonSelectedPortfolios = this.nonSelectedPortfolios.filter(({ uuid }) => uuid !== port.uuid)
                if (this.selectedPortfolioImage.uuid === port.uuid) {
                  this.selectedPortfolioImage = {};
                }
                this.EventBus.$emit('galleryPortfolio',
                  {
                    nonSelectedPortfolios: this.nonSelectedPortfolios,
                    selectedPortfolios: this.selectedPortfolios
                  });
                this.$toasted.global.infoToast({description: result.message});
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },

      removeMediaUpload() {
        this.uploadedMedia = {};
        this.uploadedCover = {};
        this.openCover = false;
        this.activatedIframe = false;
      },

      onFileChangeToUploadCover(e) {
        this.activeProgress = true;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;


        this.api.general.checkCoverIsImage(files[0].type)
          .then(({data}) => {
            let result = data;
            this.activeProgress = false;
            if (result.success) {
              this.file = files[0];
              this.canvasWidth = 1400;
              this.canvasHeight = 788;
              this.divider = 2.5;
              this.viewMode = 1;
              this.fileType = "onFileChangeToUploadCover";
              this.openCropper = true;
              e.target.value = '';
            } else {
              e.target.value = '';
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            e.target.value = '';
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      eventBusOnFileChangeToUploadCover(payload) {
        this.responseStatusToUploadCover = "started";
        this.timeStamp = +new Date();

        this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.PORTFOLIO, this.timeStamp)
          .then(result => {
            this.uploadedCover = {
              upload_id: result.externalID,
              image_url: result.url,
              width: 0,
              height: 0,
              loader: true
            };
            this.responseStatusToUploadCover = null;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.responseStatusToUploadCover = null;
          });
      },

      onFileChange(e) {
        this.mediaFile = null;
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        if (files[0].type.split("/")[0] === "image") {
          this.mediaFile = files[0];

        }
        this.uploadResponseStatus = "started";
        this.timeStamp = +new Date();
        this.iterateUploadFileToGoogle(files[0], e);
      },
      removeCoverUpload() {
        this.uploadedCover = {}
      },

      coverImageOnload(event, upload) {
        upload.width = 200;
        upload.height = 113;
        upload.loader = false;
      },

      imageOnload(event, upload) {
        upload.width = 400;
        upload.height = 226;
        upload.loader = false;
        this.openCover = true;

        if (this.mediaFile && upload.loader === false && isEmpty(this.uploadedCover)) {
          this.api.general.checkCoverIsImage(this.mediaFile.type)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.file = this.mediaFile;
                this.canvasWidth = 1400;
                this.canvasHeight = 788;
                this.divider = 2.5;
                this.viewMode = 1;
                this.fileType = "onFileChangeToUploadCover";
                this.openCropper = true;
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },

      iterateUploadFileToGoogle(files, e) {
        this.uploadResponseStatus = 'started';
        this.uploadFileToGoogle(files, this.Constants.UPLOAD_TYPES.PORTFOLIO, this.timeStamp)
          .then(result => {
            //console.log(result)
            this.uploadedMedia = {
              fileType: result.file_type,
              upload_id: result.externalID,
              image_url: result.url,
              videoUrl: result.video_url,
              width: 0,
              height: 0,
              loader: true
            };

            e.target.value = '';
            this.uploadResponseStatus = null;
          })
          .catch(err => {
            e.target.value = '';
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
          });
      },

      sendImageToGig() {
        this.nonSelectedPortfolios = this.nonSelectedPortfolios.filter(({ uuid }) => uuid !== this.selectedPortfolioImage.uuid)
        this.selectedPortfolios.push(this.selectedPortfolioImage);
        this.EventBus.$emit('galleryPortfolio', {
          nonSelectedPortfolios: this.nonSelectedPortfolios,
          selectedPortfolios: this.selectedPortfolios,
        });
        this.hide();
      },

      showPortfolioDetailModal(portfolio) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid}, description: 'keepOldModal'});
      },

      deactivateIframeLoader() {
        this.activatedIframe = false;
      },

      activateIframe() {
        this.activatedIframe = true;
      },

      dragStartEvent(event) {
        this.dragAndDropArea = true;
        if (event.target.id === "gallery-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana bırakın.';
        }

        else if (event.target.id === "gallery-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana bırakın.';
        }

      },

      dragLeaveEvent(event) {

        if (event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          this.dragAndDropArea = false;
        } else if ((!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          this.dragAndDropArea = false;
        }

        else if (event.target.id === "gallery-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana sürükle.';
        }

        else if (event.target.id === "gallery-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana sürükle.';
        }
      },

      dropHandler(event, type) {


        this.EventBus.$emit('dragAndDropFile', null);

        event.preventDefault();
        event.stopPropagation();

        if (type === 'cover') {
          this.onFileChangeToUploadCover(event);
        }
        else if (type === 'media') {
          this.onFileChange(event);
        }
        this.dragAndDropArea = false;
      },

      dragOverHandler(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },

    },
    computed: {
      getModalInfo() {
        if (!this.uuid) {
          const info = this.$store.state.activeModal.info;
          this.uuid = info.uuid;
          this.nonSelectedPortfolios = info.nonSelectedPortfolios;
          this.selectedPortfolios = info.selectedPortfolios;
          this.selectedCategory = info.selectedCategory;
          this.selectedSubCategory = info.selectedSubCategory;
        }
        return true;
      },

      disableToSaveForAddPortfolio() {
        return !this.selectedCategory || !this.selectedSubCategory || this.activeProgress || !this.title || this.uploadResponseStatus || !this.uploadedMedia.upload_id || this.responseStatusToUploadCover === 'started';
      }
    },

    created() {
      window.addEventListener('dragleave', this.dragLeaveEvent, false);
      window.addEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$on('cropperComponentImageFile', payload => {
        if (payload.fileType === "onFileChangeToUploadCover") {
          this.eventBusOnFileChangeToUploadCover(payload);
          this.openCropper = false;
          this.mediaFile = null;
        }
        if (payload.fileType === 0) {
          this.openCropper = false;
          this.mediaFile = null;
        }
      });
    },

    beforeDestroy() {
      window.removeEventListener('dragleave', this.dragLeaveEvent, false);
      window.removeEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$off("cropperComponentImageFile");
    }
  }

</script>

<style scoped lang="scss">

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .modal-container {
    font-family: 'sofia-pro';
    margin: auto;
    padding: 0;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 1000px;
    min-width: 1000px;
    max-height: 620px;
    min-height: 620px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    /*overflow-y: scroll;*/
    position: relative;
  }

  .gallery-container {

  }

  .separator {
    height: 0;
    width: 100%;
    border-top: solid 2px #e6eaea;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .section-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .gallery-modal-header {
    padding-top: 30px;
    padding-left: 30px;
  }

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 25%;
    padding-bottom: 10px;
  }

  .active-section {
    border-bottom: 3px solid #2d3640;

  }

  .section-text {
    font-size: 16px;

    margin-left: 10px;
  }

  .active-text {

    color: #2d3640;
  }

  /*section 1*/
  .all-images-container {
    height: 390px;
    margin: 0px 30px;
  }

  .all-images-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 390px;
    overflow-x: scroll;
  }

  .gallery-photo-box {
    position: relative;
    width: 220px;
    height: 124px;

    margin-top: 15px;
    cursor: pointer;
    border: 1px dashed #e1e1e1;
    margin-right: 10px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:hover {
      .iconshadow {
        visibility: visible;
      }
    }
  }

  .unselected-box {

  }

  .selected-box {

    position: relative;
    border: 2px dashed #fd4056 !important;
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      left: 10px;
      background: #fff url(https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg) no-repeat center;
      padding: 5px;
      border-radius: 50%;
    }
  }

  .gallery-photo {
    width: 220px;
    height: 124px;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    border: solid 1px #fff;
    background-color: #fff;
    width: 30px;
    height: 30px;
  }

  .zoom-icon-container {
    cursor: pointer;
    color: #fff;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 4px;
    position: absolute;
    padding: 5px;
    top: 10px;
    right: 10px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
  }

  .zoom-icon {
    width: 18px;
    height: 18px;
  }

  .iconshadow {
    visibility: hidden;
    cursor: pointer;
    position: absolute;
    opacity: 1;
    right: 20px;
    top: 10px;
  }

  /*section 2*/
  .upload-image-container {
    display: flex;
    justify-content: space-between;
    height: 426px;
    margin: 30px;
  }

  /*buttons*/
  .button-container {
    margin-right: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .back-button {
    width: 140px;
    height: 40px;
    border-radius: 2px;
    background-color: #2d3640;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    margin-right: 20px;
  }

  .next-button {
    width: 140px;
    height: 40px;
    border-radius: 2px;
    background-color: #fd4056;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    &:hover {
      background-color: #f33e53 !important;
    }

    &:disabled {
      background-color: #999 !important;
    }
  }

  .save-button {
    width: 170px;
    height: 50px;
    border-radius: 2px;
    background-color: #6dce6a;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 20px;
    &:hover {
      background-color: #5FC05D !important;
    }

    &:disabled {
      background-color: #999 !important;
    }
  }

  /*portfolio*/
  .portfolio-add-left-side {
    flex: 3
  }

  .portfolio-title-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .portfolio-cover-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .cover-label-container {
    cursor: pointer;
    height: 113px;
    margin: 0;
  }

  .cover-header {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    color: #bfc8d2
  }

  .cover-container-passive {
    width: 200px;
    height: 113px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .cover-container-active {
    width: 200px;
    height: 113px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .cover-remove-button {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 20px;
  }

  .portfolio-add-right-side {
    flex: 4
  }

  .portfolio-media-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .media-container-passive {
    width: 350px;
    height: 226px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .media-label-container {
    cursor: pointer;
    height: 226px;
    margin: 0;
  }

  .image-header {
    margin-top: 5px;
    font-size: 17px;
    font-weight: 400;
    color: #bfc8d2;
  }

  .media-container-active {
    position: relative;
    max-width: 400px;
    max-height: 350px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .media-remove-button {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 20px;
    visibility: visible;
  }

  .media-image {
    max-width: 400px;
    max-height: 350px;
  }

  .audio-container {
    position: relative;
  }

  .media-play-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    border-radius: 50%;
    border: solid 1px #fff;
    background-color: #fff;
  }

  .video-container {
    width: 360px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(236, 249, 245);
    border: 1px solid rgb(103, 204, 100);
    padding: 8px 10px 8px 30px;
    margin-bottom: 15px;

    .video-separator {
      height: 40px;
      width: 0;
      margin-left: 19px;
      border-left: solid 1px #e6eaea;
    }

    .warning-text {
      font-size: 13px;
      margin-left: 18px;
      color: rgb(103, 204, 100);
    }
  }

  /*dragdrop*/
  #gallery-drag-drop-cover {
    position: relative;
    height: 115px;
    width: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 13px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }

  #gallery-drag-drop-media {
    position: relative;
    height: 230px;
    width: 355px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 15px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }
</style>
